import "./drawing-preview.styles.scss";

import { LazyLoadImage } from "react-lazy-load-image-component";
import Window from "../window/window.component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function DrawingPreview({ id }) {
  const path = `/drawings/${id}.png`;
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(true);

  const onClick = () => {
    navigate(`/drawing/${id}`);
  };

  return (
    <div
      className="DrawingPreview"
      onMouseEnter={() => {
        setHidden(false);
      }}
      onMouseLeave={() => {
        setHidden(true);
      }}
      onClick={onClick}
    >
      <Window
        title={`Flash id: ${id}`}
        closable={false}
        hidden={hidden}
        transparentContentBg={true}
      >
        <LazyLoadImage src={path} alt="drawing" />
      </Window>
    </div>
  );
}

export default DrawingPreview;
