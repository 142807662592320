import "./drawing-page.styles.scss";

import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/button/button.component";
import Window from "../../components/window/window.component";

function DrawingPage() {
  const { id } = useParams();
  const path = `/drawings/${id}.png`;

  const navigate = useNavigate();

  const onClose = () => {
    navigate(`/`);
  };

  const openCalendly = () => {
    window
      .open(
        "https://calendly.com/reassemblingskeleton/tattoo-with-reassembling-skeleton",
        "_blank"
      )
      .focus();
  };

  return (
    <div className="DrawingPage">
      <div className="drawingPage">
        <Window title={`Flash id: ${id}`} onClose={onClose}>
          <div className="WindowContent">
            <img src={path} alt="flash drawing" />
          </div>
        </Window>
        <div className="text">
          <h2>1) Set your appointment</h2>
          <h2>2) screenshot/save your desired tattoo </h2>
          <h2>
            3) send it to me on Instagram ->{" "}
            <a
              href="https://www.instagram.com/reassemblingskeleton/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @reassemblingskeleton
            </a>
          </h2>
        </div>
        <Button
          title="Book now!"
          onClick={openCalendly}
          iconPath="/icons/welcome.GIF"
        />
      </div>
    </div>
  );
}

export default DrawingPage;
