import "./header.styles.scss";

import BackgroundGifLayer from "../background-gif-layer/background-gif-layer.component";
import Button from "../button/button.component";
import Window from "../window/window.component";
import { useDate } from "../use-time/use-time.component";

function Header() {
  const time = useDate();

  return (
    <div className="Header">
      <div className="Background">
        <BackgroundGifLayer />
        <Window title="Welcome!" closable={false}>
          <div className="Welcome">
            <div className="Logo">
              <img src="logo.PNG" alt="Reassembling Skeleton" />
            </div>
            <div className="Subtitle">
              Tattoo artist based in Ottawa, Ontario
            </div>
          </div>
        </Window>
      </div>
      <div className="Taskbar">
        <div className="TaskbarOverlay">
          <img src="./header-gifs/taskbar.GIF" alt="taskbar" />
        </div>
        <div className="StartAndWelcome">
          <Button title="Start" iconPath="/icons/windows.ico" />
          <div className="WelcomeTab">
            <div className="welcomeTab">
              <img src="/icons/welcome.gif" alt="icon" />
              <p>welcome.exe</p>
            </div>
          </div>
        </div>

        <div className="Time"><p>{time}</p></div>
      </div>
    </div>
  );
}

export default Header;
