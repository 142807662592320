import "./window.styles.scss";

function Window({ title, children, onClose, closable = true, hidden = false, transparentContentBg = false}) {
  const contentClass = transparentContentBg ? "contentTransparent" : "content";
  
  return (
    <div className={`${hidden ? "WindowHidden" : "Window"}`}>
      <div className={`${hidden ? "windowHidden" : "window"}`}>
        <div className={`${hidden ? "winHidden" : "win"}`}>
          <div className={`${hidden ? "WindowHeaderHidden" : "WindowHeader"}`}>
            <div
              className={`${hidden ? "windowHeaderHidden" : "windowHeader"}`}
            >
              {!hidden && <div className="Title">{title}</div>}
              {closable && !hidden && (
                <div className="Close" onClick={onClose}>
                  X
                </div>
              )}
            </div>
          </div>
          <div className={`${hidden ? "ContentHidden" : "Content"}`}>
            <div className={`${hidden ? "contentHidden" : contentClass}`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window;
