import "./nav.styles.scss";

import { useEffect, useState } from "react";

import Button from "../button/button.component";
import MinimizableWindow from "../minimizable-window/minimizable-window.component";
import useWindowDimensions from "../window-dimensions/window-dimensions.component";

function Nav({ canvasRef }) {
  const windowDimensions = useWindowDimensions();
  const [minimized, setMinimized] = useState(windowDimensions.width < 1200);

  useEffect(() => {
    if (windowDimensions.width < 1200) {
      setMinimized(true);
    } else {
      setMinimized(false);
    }
  }, [windowDimensions.width]);

  useEffect(() => {
    window.addEventListener("scroll", pop);
    return () => window.removeEventListener("scroll", pop);
  });

  const pop = () => {
    if (
      window.scrollY > windowDimensions.height - 300 &&
      window.scrollY < windowDimensions.height
    ) {
      setMinimized(true);
    }

    if (window.scrollY < windowDimensions.height - 300 && windowDimensions.width >= 1200) {
      setMinimized(false);
    }
  };

  const scrollToPortfolio = () => {
    canvasRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const openInstagram = () => {
    window
      .open("https://www.instagram.com/reassemblingskeleton/", "_blank")
      .focus();
  };

  const contact = () => {
    window.open("mailto:Reassemblingskeletontattoo@gmail.com");
  };

  const openCalendly = () => {
    window
      .open(
        "https://calendly.com/reassemblingskeleton/tattoo-with-reassembling-skeleton",
        "_blank"
      )
      .focus();
  };

  return (
    <div className="Nav">
      <MinimizableWindow
        title="Menu"
        minimized={minimized}
        close={() => {
          setMinimized(true);
        }}
        open={() => {
          setMinimized(false);
        }}
      >
        <div className="Buttons">
          <Button
            title="Portfolio"
            onClick={scrollToPortfolio}
            iconPath="/icons/skeleton.GIF"
          />
          <Button
            title="Instagram"
            onClick={openInstagram}
            iconPath="/icons/skeleton.GIF"
          />
          <Button
            title="Contact"
            onClick={contact}
            iconPath="/icons/skeleton.GIF"
          />
          <Button
            title="Booking"
            onClick={openCalendly}
            iconPath="/icons/skeleton.GIF"
          />
        </div>
      </MinimizableWindow>
    </div>
  );
}

export default Nav;
