import { useEffect, useState } from "react";

import "./canvas.styles.scss";
import DrawingPreview from "../drawing-preview/drawing-preview.component";

const importAll = (r) => {
  return r.keys().map(r);
};

const getId = (s) => {
  return s.substring(s.lastIndexOf("/") + 1, s.indexOf("."));
};

function Canvas({ canvasRef }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const newImages = importAll(
      require.context("../../../public/drawings/", false, /\.(png|PNG|jpe?g|svg)$/)
    );
    setImages(newImages);
  }, []);

  return (
    <div className="Canvas" ref={canvasRef}>
      {images.map((image) => (
        <DrawingPreview key={getId(image)} id={getId(image)} />
      ))}
    </div>
  );
}

export default Canvas;
