import "./minimizable-window.styles.scss";
import Window from "../window/window.component";

function MinimizableWindow({ title, minimized, close, open, children }) {
  return (
    <div className="MinimizableWindow">
      {minimized && (
        <div className="WindowMinimized" onClick={open}>
          <div className="windowMinimized">
            <div className="MinimizedTitle">{title}</div>
          </div>
        </div>
      )}

      {!minimized && (
        <Window title={title} onClose={close}>
          {children}
        </Window>
      )}
    </div>
  );
}

export default MinimizableWindow;
