import "./button.styles.scss";

function Button({ title, onClick, iconPath = null }) {
  return (
    <div className="Button" onClick={onClick}>
      <div className="button">
        {iconPath && <img src={iconPath} alt="icon" />}
        {title}
      </div>
    </div>
  );
}

export default Button;
