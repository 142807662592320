import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home.component";
import DrawingPage from "./pages/drawing-page/drawing-page.component";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="drawing/:id" element={<DrawingPage />} />
      </Route>
    </Routes>
  );
}

export default App;
