import { useRef } from "react";
import Canvas from "../../components/canvas/canvas.component";
import Header from "../../components/header/header.component";
import Nav from "../../components/nav/nav.component";

import "./home.styles.scss";

function Home() {
  const canvasRef = useRef(null);
  return (
    <div className="Home">
      <Nav canvasRef={canvasRef} />
      <Header />
      <Canvas canvasRef={canvasRef} />
    </div>
  );
}

export default Home;
