import "./background-gif-layer.styles.scss";

function BackgroundGifLayer() {
  return (
    <div className="BackgroundGifLayer">
      <img src="./header-gifs/rose.GIF" alt="rose" className="rose" />

      <img src="./header-gifs/dragon.GIF" alt="dragon" className="dragon" />
      
      <img src="./header-gifs/chandelier2.GIF" alt="chandelier" className="chandelier" />

      <img
        src="./header-gifs/lightning.GIF"
        alt="lightning"
        className="lightning1"
      />

      <img src="./header-gifs/castle.GIF" alt="castle" className="castle" />

      <img
        src="./header-gifs/lightning.GIF"
        alt="lightning"
        className="lightning2"
      />

      <img src="./header-gifs/death.GIF" alt="death" className="death" />

      <img src="./header-gifs/chandelier2.GIF" alt="chandelier" className="chandelier2" />

      <img src="./header-gifs/death2.GIF" alt="death2" className="death2" />

      <img src="./header-gifs/goofy.GIF" alt="goofy" className="goofy" />
    </div>
  );
}

export default BackgroundGifLayer;
